import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout'

const Welfare = () => {

    const data = useStaticQuery(graphql`
    {
        allContentfulWickCollegeBranch(sort: {order: ASC, fields: createdAt}) {
        nodes {
        address
        facilityName
        fax
        phone
        url
        }
    }
        allContentfulToronkoAcademyBranch(sort: {order:ASC , fields: createdAt}) {
        nodes {
        address
        facilityName
        fax
        phone
        url
        exterior {
            gatsbyImageData
        }
        anotherInterior {
            gatsbyImageData
        }
        firstInterior {
            gatsbyImageData
        }
        }
    }
        allContentfulKonokonoArt(sort: {order:ASC , fields: createdAt}) {
        nodes {
        address
        fax
        name
        tel
        url
        exteriorPhoto {
            gatsbyImageData
        }
        interiorPhoto {
            gatsbyImageData
        }
        anotherInteriorPhoto {
            gatsbyImageData
        }
        }
    }
    }
  `)

    const konokonoBranch = data.allContentfulKonokonoArt.nodes
    const toronkoBranch = data.allContentfulToronkoAcademyBranch.nodes
    const wickCollege = data.allContentfulWickCollegeBranch.nodes


    return (
        <>
            <Helmet
                title="福祉事業部ページ - マコム・プラニング公式サイト"
                description="マコム・プラニングの福祉事業部に関するページです。このこのアート、とろんこアカデミー等の支店リストや新事業の概要をご確認いただけます。"
            />
            <Layout>
                {/* Hero Part */}
                <section className="relative py-40">
                    <div className="absolute top-0 left-0 lg:w-4/5 w-full h-full bg-pink-50/50 rounded-r-full" />
                    <div className="relative container px-4 h-full mx-auto">
                        <div className="relative flex flex-wrap items-center justify-center -mx-4">
                            <div className="relative z-10 w-full md:w-auto lg:w-3/5 px-4">
                                <div className="mx-auto text-center">
                                    <span className="text-pink-300 font-semibold">Welfare Department</span>
                                    <h1 className="mt-8 mb-6 lg:mb-10 text-4xl font-semibold">福祉事業部のご案内</h1>
                                    <h2 className="mb-8 lg:mb-12 text-gray-600">その人が生きていくことの糧になることを見つけ、その活力、エネルギーが削がれないようにサポートする“を支援軸とし、複数のブランド展開をして活動しております。</h2>
                                    <Link id='enquiry' className="px-10 py-4 text-sm leading-normal ring-1 hover:ring-0 ring-slate-600 text-slate-600 hover:bg-pink-300 hover:text-white duration-500 rounded-full" to="/#enquiry">お問い合わせはこちら</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* KonoKono Art Introductions */}
                <section className="">
                    <article className="pt-20">
                        <div className="container px-4 mx-auto text-center">
                            <div className="max-w-2xl mx-auto mb-16">
                                <span className="text-xs text-red-400 font-semibold">Our Brand 1 - konokono art</span>
                                <h2 className="mt-2 mb-4 text-4xl font-semibold font-heading">このこのアート</h2>
                                <p className="text-sm text-gray-500 mb-2 leading-relaxed">これでいいんだ。</p>
                                <p className="text-sm text-gray-500 mb-2 leading-relaxed">アート活動による自己表現力を養うことで劣等感、自己卑下を省き、自己肯定感を高める支援を行う。そのためには上に立った指導、しつけではなく、児童との信頼関係を築き、児童の人格、能力、背景を考慮し、療育を行います。</p>
                                <p className="text-sm text-gray-500 mb-2 leading-relaxed">その人が好きなこと、興味のあること、得意なことを見いだし、楽しく学ぶことで、児童のやる気を伸ばし『やれる、できる』心を育て、その人の人生を前向きにとらえ、大なり小なりのチャレンジ精神を養うための支援を行います。</p>
                                <p className="text-sm text-gray-500 mb-2 leading-relaxed">個展や展覧会出展などを主なアウトプットの方法として児童の個性溢れる作品を多くの人に届けていきます。</p>
                            </div>
                        </div>
                    </article>
                    {konokonoBranch.map((k, index) => {
                        return (
                            <article className="py-10 mb-24 md:mb-0" key={index}>
                                <div className="container px-4 mx-auto">
                                    <div className="flex flex-wrap items-center -mx-4">
                                        <div className="w-full lg:w-1/2 px-4 mb-12 lg:mb-0">
                                            <div className="max-w-lg">
                                                <h2 className="mt-8 mb-6 lg:pr-8 text-3xl font-semibold">{k.name}</h2>
                                                <p className="mb-2 text-sm text-gray-500">{k.address}</p>
                                                <p className="mb-2 text-sm text-gray-500">Tel: {k.tel}</p>
                                                <p className="mb-6 text-sm text-gray-500">Fax: {k.fax}</p>
                                                <a id='konoKonoArtBlog' className="inline-block py-4 px-8 text-sm text-white font-medium leading-normal bg-red-400 hover:bg-red-300 transition duration-200 rounded" href={k.url} target="_blank" rel="noreferrer">ブログはこちら</a>
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-1/2 px-4">
                                            <div className="flex flex-wrap -mx-3">
                                                <div className="flex flex-wrap w-full h-screen">
                                                    <div className="w-full lg:w-1/2 h-64 lg:h-1/3 p-3">
                                                        <GatsbyImage className="hover:scale-125 duration-1000 ease-in-out hover:z-40 hover:-translate-y-6 w-full h-full object-cover rounded-lg object-top" image={getImage(k.interiorPhoto.gatsbyImageData)} alt={k.facilityName} />
                                                    </div>
                                                    <div className="w-full lg:w-1/2 h-64 lg:h-1/3 p-3">
                                                        <GatsbyImage className="hover:scale-125 duration-1000 ease-in-out hover:z-40 hover:-translate-y-6 w-full h-full object-cover rounded-lg" image={getImage(k.anotherInteriorPhoto.gatsbyImageData)} alt={k.facilityName} />
                                                    </div>
                                                    <div className="w-full h-64 lg:h-2/3 p-3">
                                                        <GatsbyImage className="hover:scale-125 duration-1000 ease-in-out hover:z-40 hover:-translate-y-6 w-full h-full object-cover rounded-lg" image={getImage(k.exteriorPhoto.gatsbyImageData)} alt={k.facilityName} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        )
                    })}
                </section>

                {/* Toronko Academy Introductions */}
                <section className="">
                    <article className="pt-20">
                        <div className="container px-4 mx-auto text-center">
                            <div className="max-w-2xl mx-auto mb-16">
                                <span className="text-xs text-orange-400 font-semibold">Our Brand 2 - toronko academy</span>
                                <h2 className="mt-2 mb-4 text-4xl font-semibold font-heading">とろんこアカデミー</h2>
                                <p className="text-sm text-gray-500 mb-2 leading-relaxed">できない不安をできる自信へ。</p>
                                <p className="text-sm text-gray-500 mb-2 leading-relaxed">子ども達自身の努力が華になり、実になる。それを支える大きな幹のような存在・場所でとろんこアカデミー、とろんこアカデミーブリックはありたい。</p>
                                <p className="text-sm text-gray-500 mb-2 leading-relaxed">個別指導でサポートし、個性を伸ばして、やる気につなげる。ひとりひとりに沿ったカリキュラムで個々をサポートします。</p>
                                <p className="text-sm text-gray-500 mb-2 leading-relaxed">私たちが取り組むのは子ども達が自ら考え、学び、行動する力が育つ。そんな場所を創ります。</p>
                                <p className="text-sm text-gray-500 mb-2 leading-relaxed">学ぶことで、自信をつけ、ちょっと背伸びしたチャレンジを行えるこころをまた自分の個性を理解し、その児童にとって、その個性を大切に出来るこころを養います。そして得意なことを見いだし、伸ばし、そして、『やれる、できる』心を育てます。</p>
                            </div>
                        </div>
                    </article>
                    {toronkoBranch.map((t, index) => {
                        return (
                            <article className="py-10 mb-24 md:mb-0" key={index}>
                                <div className="container px-4 mx-auto">
                                    <div className="flex flex-wrap items-center -mx-4">
                                        <div className="w-full lg:w-1/2 px-4 mb-12 lg:mb-0">
                                            <div className="max-w-lg">
                                                <h2 className="mt-8 mb-6 lg:mb-10 lg:pr-8 text-3xl font-semibold">{t.facilityName}</h2>
                                                <p className="mb-2 text-sm text-gray-500">{t.address}</p>
                                                <p className="mb-2 text-sm text-gray-500">Tel: {t.phone}</p>
                                                <p className="mb-6 text-sm text-gray-500">Fax: {t.fax}</p>
                                                <a id='tronkoAcademyBlog' className="inline-block py-4 px-8 text-sm text-white font-medium leading-normal bg-orange-400 hover:bg-orange-300 transition duration-200 rounded" href={t.url} target="_blank" rel="noreferrer">ブログはこちら</a>
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-1/2 px-4">
                                            <div className="flex flex-wrap -mx-3">
                                                <div className="flex flex-wrap w-full h-screen">
                                                    <div className="w-full lg:w-1/2 h-64 lg:h-1/3 p-3">
                                                        <GatsbyImage className="hover:scale-125 duration-1000 ease-in-out hover:z-40 hover:-translate-y-6 w-full h-full object-cover rounded-lg object-top" image={getImage(t.firstInterior.gatsbyImageData)} alt={t.facilityName} />
                                                    </div>
                                                    <div className="w-full lg:w-1/2 h-64 lg:h-1/3 p-3">
                                                        <GatsbyImage className="hover:scale-125 duration-1000 ease-in-out hover:z-40 hover:-translate-y-6 w-full h-full object-cover rounded-lg" image={getImage(t.anotherInterior.gatsbyImageData)} alt={t.facilityName} />
                                                    </div>
                                                    <div className="w-full h-64 lg:h-2/3 p-3">
                                                        <GatsbyImage className="hover:scale-125 duration-1000 ease-in-out hover:z-40 hover:-translate-y-6 w-full h-full object-cover rounded-lg" image={getImage(t.exterior.gatsbyImageData)} alt={t.facilityName} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        )
                    })}
                </section>

                {/* Wick College Introductions */}
                <section className="bg-white">
                    <article className="pt-20">
                        <div className="container px-4 mx-auto text-center">
                            <div className="max-w-2xl mx-auto mb-16">
                                <span className="text-xs text-blue-400 font-semibold">Our Brand 3 - Wick College</span>
                                <h2 className="mt-2 mb-4 text-4xl font-semibold font-heading">ウィックカレッジ</h2>
                                <p className="text-sm text-gray-500 mb-2 leading-relaxed">ここで働きたい、こんな生活がしたい、恋がしたい、子供をもちたい、そんな健常者なら当たり前の希望を障がいを持つ人に実現してほしい。</p>
                                <p className="text-sm text-gray-500 mb-2 leading-relaxed">そのためには健常者と同じように障がいを持つ人も納税者であるべきはないだろうか。「このこのアート」「とろんこアカデミー」を運営しているうちに、そんな答えに至りました。しかしそんな事業所があるのか。なかなかない……。</p>
                                <p className="text-sm text-gray-500 mb-2 leading-relaxed">それならば我々がつくろう。それがウイックカレッジ設立の根本です。</p>
                                <p className="text-sm text-gray-500 mb-2 leading-relaxed">弊社が考える、このこのアート、とろんこアカデミーの先の、障がい者の未来です。</p>
                                <p className="text-sm text-gray-500 mb-2 leading-relaxed">健常の方々は高校を卒業すれば大学、専門学校と“学び” の門戸が広いのに対して発達に凸凹がある障がいを持つ方々の門戸は狭いのが現実です。残念ながら、その流れの一端を作っているのが既存の福祉施設かもしれません。</p>
                                <p className="text-sm text-gray-500 mb-2 leading-relaxed">ウイックカレッジは自分らしい生活への学びと企業への就労（障がい者雇用枠など）を目指します。</p>
                            </div>
                        </div>
                    </article>
                    {wickCollege.map((w, index) => {
                        return (
                            <article className="py-10 mb-24 md:mb-0" key={index}>
                                <div className="container px-4 mx-auto">
                                    <div className="flex flex-wrap items-center -mx-4">
                                        <div className="w-full lg:w-1/2 px-4 mb-12 lg:mb-0">
                                            <div className="max-w-lg">
                                                <h2 className="mt-8 mb-6 lg:mb-10 lg:pr-8 text-3xl font-semibold">{w.facilityName}</h2>
                                                <p className="mb-2 text-sm text-gray-500">{w.address}</p>
                                                <p className="mb-2 text-sm text-gray-500">Tel: {w.phone}</p>
                                                <p className="mb-6 text-sm text-gray-500">Fax: {w.fax}</p>
                                                <a id='wickCollegeBlog' className="inline-block py-4 px-8 text-sm text-white font-medium leading-normal bg-blue-400 hover:bg-blue-300 transition duration-200 rounded" href={w.url} target="_blank" rel="noreferrer">ブログはこちら</a>
                                            </div>
                                        </div>
                                        {/* <div class="relative w-full lg:w-1/2 px-4">
                                            <div class="flex flex-wrap -mx-3">
                                                <div class="flex flex-wrap w-full h-screen">
                                                    <div class="w-full lg:w-1/2 h-64 lg:h-1/3 p-3">
                                                        <GatsbyImage class="w-full h-full object-cover rounded-lg object-top" image={getImage(w.firstInterior.gatsbyImageData)} alt={w.facilityName} />
                                                    </div>
                                                    <div class="w-full lg:w-1/2 h-64 lg:h-1/3 p-3">
                                                        <GatsbyImage class="w-full h-full object-cover rounded-lg" image={getImage(w.anotherInterior.gatsbyImageData)} alt={w.facilityName} />
                                                    </div>
                                                    <div class="w-full h-64 lg:h-2/3 p-3">
                                                        <GatsbyImage class="w-full h-full object-cover rounded-lg" image={getImage(w.exterior.gatsbyImageData)} alt={w.facilityName} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </article>
                        )
                    })}
                </section>

                {/* bansou Introductions */}
                <section className="">
                    <article className="py-20">
                        <div className="container px-4 mx-auto text-center">
                            <div className="max-w-2xl mx-auto mb-16">
                                <span className="text-xs text-violet-400 font-semibold">Our Brand 4 - bansou</span>
                                <h2 className="mt-2 mb-4 text-4xl font-semibold font-heading">バンソウ</h2>
                                <p className="text-sm text-gray-500 mb-2 leading-relaxed">個別支援計画書管理システム[bansou]の運営管理販売</p>
                            </div>
                        </div>
                        <h2 className="font-bold mx-auto text-center">Coming Soon</h2>
                    </article>
                </section>
            </Layout>
        </>
    )
}

export default Welfare
